<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6 offset-md-3">
        <div class="card">
          <div class="card-body">
            <ValidationObserver v-slot="{ handleSubmit, invalid, touched }">
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(validateCard)"
              >
                <AddCreditCard ref="cardForm" />
                <b-form-group class="mt-2">
                  <b-button
                    variant="primary"
                    :disabled="loadingCreate || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingCreate" small />
                    <span v-else>Create</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddCreditCard from '../../Common/AddCreditCard'

export default {
  components: { AddCreditCard },

  data() {
    return {
      loadingCreate: false,
    }
  },

  mounted() {},

  methods: {
    async validateCard() {
      this.loadingCreate = true

      await this.$refs.cardForm
        .validate()
        .then((paymentMethodId) => {
          this.createPaymentMethod(paymentMethodId)
        })
        .catch(() => {
          this.loadingCreate = false
        })
    },

    createPaymentMethod(paymentMethodId) {
      this.loadingCreate = true

      this.$store
        .dispatch('paymentMethod/create', paymentMethodId)
        .then(() => {
          this.loadingCreate = false
          this.$router.push({ name: 'agency.profile'})
        })
        .catch(() => {
          this.loadingCreate = false
        })
    },
  },
}
</script>
